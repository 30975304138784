import { createStore } from "vuex";
import axiosClient from "@/AxiosClient";

export default createStore({
  state:{
    userData: {},
    loading: false,
  },
  mutations:{
    setUserData (state, userData) {
      state.userData = userData;
    }
  },
  actions:{
    async loadUserData({state,commit}){
      state.loading = true;
        await axiosClient.get('EmployeeStatement').then(data => {commit('setUserData', data.data); state.loading = false;});
     }
  },
  getters:{
    getUserData(state){
      return state.userData
    }
  },
});
