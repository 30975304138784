import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/achievements",
      name: "achievements",
      component: () => import("@/views/AchievementView.vue"),
    },
    {
      path: "/message",
      name: "message",
      component: () => import("@/views/MessageQuote.vue"),
    },
    {
      path: "/overview",
      name: "overview",
      component: () => import("@/views/ProfileOverview.vue"),
    },
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/TopbarView.vue"),
      children: [
        {
          path: "pay",
          name: "pay",
          component: () => import("@/views/PayView.vue"),
        },
        {
          path: "protection",
          name: "protection",
          component: () => import("@/views/ProtectionView.vue"),
        },
        {
          path: "retirement",
          name: "retirement",
          component: () => import("@/views/RetirementView.vue"),
        },
        {
          path: "wellbeing",
          name: "wellbeing",
          component: () => import("@/views/WellbeingView.vue"),
        },
        {
          path: "rewards",
          name: "rewards",
          component: () => import("@/views/RewardsView.vue"),
        },
      ],
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
