// // Styles
// import '@mdi/font/css/materialdesignicons.css'
// import 'vuetify/styles'

// // Vuetify
// import { createVuetify } from 'vuetify'

// export default createVuetify(
//   // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// )

import { createVuetify } from 'vuetify'

import 'vuetify/dist/vuetify.min.css'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#F8F8F8',
    surface: '#FFFFFF',
    primary: '#6200EE',
    tertiary: '#15808D',
    'white': '#FFFFFF',
    secondary: '#F15A22',
    'secondary-darken-1': '#124E57',
    'page': '#F69521',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  }
}

export default new createVuetify({
  theme: {
    options: {
      customProperties: true
    },
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  },

  icons: {
    iconfont: 'fa'
  }
})
