<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script lang="js">
export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/hero-new');

body {
  background-color: #f8f8f8;
  font-family: 'Hero New', sans-serif;
}
</style>